<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { importAction } from '@/command/netTool'
export default {
  name: 'indexVoucher',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    this.getInit()
  },
  methods: {
    getInit() {
      api.command.getList.call(this, {
        url: '/farmUserBalance/list',
        current: 1,
        paramsValue: {
          balanceId: this.$route.query.id
        }
      })
    },
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '手机号',
          key: 'telephone',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'telephone',
          title: '手机',
          align: 'left'
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left'
        },
        {
          dataIndex: 'usedAmount',
          title: '消费金额',
          align: 'left',
          sorter: (a, b) => a.usedAmount - b.usedAmount
        },
        {
          dataIndex: 'unUsedAmount',
          title: '剩余金额',
          align: 'left',
          sorter: (a, b) => a.unUsedAmount - b.unUsedAmount
        },
        {
          dataIndex: 'allAmount',
          title: '发放金额',
          align: 'left',
          sorter: (a, b) => a.allAmount - b.allAmount
        },
        {
          dataIndex: 'updateDate',
          title: '最后消费时间',
          align: 'left'
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/farmUserBalance/del',
                    params: {
                      id: records.id
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '手机号',
              key: 'phone',
              labelAlign: 'right',
              type: 'input',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            },
            {
              name: '名称',
              key: 'name',
              labelAlign: 'right',
              type: 'input',
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            },
            {
              name: '金额',
              key: 'amount',
              labelAlign: 'right',
              type: 'input',
              placeholder: '不输入则默认金额'
            }
          ]
        }
      ]
      return form
    },
    handlePopup(
      dataSource = {
        amount: ''
      }
    ) {
      let that = this
      let data = this.getDrawerForm(dataSource)
      apiTool.showDrawer({
        title: '设置',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data,
          form: dataSource,
          formProps: {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 }
          }
        },
        success: ({ data, setHidden }) => {
          let lxfs = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/
          if (!lxfs.test(data.phone)) {
            this.$message.warning('请填写或输入正确手机号！')
            return false
          }
          if (!data.name) {
            this.$message.warning('请填写或输入名称！')
            return false
          }
          if (data.amount && !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(data.amount)) {
            this.$message.warning('请填写或输入正确金额(小数点保留两位)！')
            return false
          }
          api.command[data.id ? 'edit' : 'create']
            .call(that, {
              url: '/farmUserBalance/addUserBalance',
              params: {
                ...data,
                balanceId: that.$route.query.id
              }
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handlePopup()
          }
        }
      ]
    },
    getRightButton() {
      return [
        {
          name: '导入Excel',
          onClick: () => {
            importAction('/api/farmUserBalance/importData?balanceId=' + this.$route.query.id)
              .then(() => {
                this.$message.success('导入成功')
                this.getInit()
              })
              .catch(err => {
                this.$message.error('导入失败：' + err.msg)
              })
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        rightButton={this.getRightButton()}
      />
    )
  }
}
</script>
